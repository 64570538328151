export const EMAIL_TEMPLATES: string[] = [
  'Hello {{customerName}}, how can I help you?',
  'Hello, I am your agent! My name is {{agentName}}, how can I help you?',
  'Is your email address {{customerEmail}}?',
  'My email is {{agentEmail}}, you can write me anytime if this problem occurs.',
  'I see that you have issues with your WiFi router. I will have to check the connection. Please wait a moment.',
  'I see that you have issues with your WiFi mesh. Let me run a few tests so we can determine what is the exact issue.',
];

export const MESSAGE_TEMPLATES: string[] = [
  'Hello {{customerName}}, how can I help you?',
  'Hello, I am your agent! My name is {{agentName}}, how can I help you?',
  'Is your email address {{customerEmail}}?',
  'My email is {{agentEmail}}, you can write me anytime if this problem occurs.',
  'I see that you have issues with your WiFi router. I will have to check the connection. Please wait a moment.',
  'I see that you have issues with your WiFi mesh. Let me run a few tests so we can determine what is the exact issue.',
];
