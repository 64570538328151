import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { Menu, MenuItem } from '@blueprintjs/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

import { getSchemaByIdRequest } from '../../../../../../core/schemas/store/actions';
import { getSchemaFromShortListBySchemaId } from '../../../../../../shared/utilities/schemaHelpers';
import { displayMessage } from '../../../../../../shared/system/messages/store/reducers';

import { useAction } from '../../../../../../containers/ProjectModule/hooks/useAction';
import { useRequest } from '../../../../../../containers/ProjectModule/hooks/useRequest';
import { getErrorMessage } from '../../../utils/errors';

import { SchemaDetails } from './SchemaDetails';
import PipelineDetails from './PipelineDetails';
import SchemaTypesList from './SchemaTypes/ListView';
import './styles.scss';

type View = 'schema-details' | 'pipelines' | 'schema-types';

const ControlPanelView: React.FC<RouteComponentProps<{ id: string}>> = ({ history, location, match }) => {
  const schemaShortList = useSelector((state: any) => state.schemaReducer.shortList);

  const [isSchemaLoading, setIsSchemaLoading] = useState(false);
  const [schema, setSchema] = useState<SchemaEntity>();
  const [currentView, setCurrentView] = useState<View>('schema-details');
  const alertMessage = useAction(displayMessage);

  const getSchemaById = useRequest(getSchemaByIdRequest);

  useEffect(() => {
    let mounted = true;
    if (!match.params?.id) return;

    setIsSchemaLoading(true);
    const schema = getSchemaFromShortListBySchemaId(schemaShortList, match.params.id);
    if (schema) {
      setSchema(schema);
      setIsSchemaLoading(false);
      return;
    }

    getSchemaById({ schemaId: match.params.id }).then(schema => {
      if (!mounted) return;

      setSchema(schema);
      setIsSchemaLoading(false);
    }).catch(error => {
      if (!mounted) return;

      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not retrieve schema. ' + message,
        type: 'error',
      });
    });

    return () => {
      mounted = false;
    }
  }, [match.params?.id]);

  return (
    <Row>
      {/* Navbar */}
      <Col span={3} style={{ padding: 5 }}>
        <Menu style={{ background: 'transparent', position: 'sticky' }}>
          <MenuItem
            icon="arrow-left"
            text="Schema Manager"
            style={{ fontWeight: 600, marginBottom: 5, cursor: 'default' }}
            onClick={() => history.push('/ControlPanelModule/SchemaManager')}
          />
          {/* <MenuItem
            text="Permissions"
          /> */}
          <MenuItem
            text="Properties"
            onClick={() => {
              setCurrentView('schema-details');
              setTimeout(() => {
                const properties = document.querySelector('#schema-properties');
                properties?.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
              }, 250);
            }}
          />
          {/* <MenuItem
            text="View Configs"
          /> */}
          <MenuItem
            text="Schema Types"
            onClick={() => setCurrentView('schema-types')}
          />
          <MenuItem
            text="Process Pipelines"
            onClick={() => setCurrentView('pipelines')}
          />
        </Menu>
      </Col>

      {/* Content */}
      <Col span={21} style={{ height: '95vh' }}>
        {currentView === 'schema-details' && (
          <SchemaDetails
            schema={schema}
            loading={isSchemaLoading}
          />
        )}
        {currentView === 'pipelines' && (
          <PipelineDetails
            schema={schema}
          />
        )}

        {currentView === 'schema-types' && (
          <SchemaTypesList
            schema={schema}
          />
        )}
      </Col>
    </Row>
  );
};

export default withRouter(ControlPanelView);
