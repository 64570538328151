export const DUMMY_DASHBOARD_PROPERTIES = [
  { name: 'Abandoned', value: '11%' },
  { name: 'Avg. Wait', value: '2:03' },
  { name: 'Max Wait', value: '5:44' },
  { name: 'Avg Answer', value: 11 },
  { name: 'Avg Length', value: '18:32' },
  { name: 'Max Time', value: '22:54' },
  { name: 'Call Spin', value: 3 },
  { name: 'CSAT', value: '80%' },
];

export const DUMMY_STAGES = [
  {
    name: 'Open',
    value: 0,
  },
  {
    name: 'Blocked',
    value: 0,
  },
  {
    name: 'Pending Reply',
    value: 0,
  },
  {
    name: 'Pending Agent',
    value: 0,
  },
  {
    name: 'Pending Review',
    value: 0,
  },
  {
    name: 'Solved',
    value: 0,
  },
  {
    name: 'Closed',
    value: 0,
  },
];
