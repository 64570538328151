import { Button, Divider, InputGroup, Section, Tag, TagInput, TextArea } from '@blueprintjs/core';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../..';
import {
  createRecordsRequest,
  getRecordByIdRequest,
  ICreateRecords,
  IGetRecordById,
} from '../../../../../../../core/records/store/actions';
import { MY_CASES_TOGGLE_EMAIL_EDITOR } from '../../store/constants';
import './styles.scss';
import MessageTemplateHandler from '../../../../../../shared/components/MessageTemplateHandler';
import FileUploaderPopoverButton from '../../../../../../shared/components/FileUploaderPopoverButton';
import { httpPost } from '../../../../../../../shared/http/requests';
import { displayMessage } from '../../../../../../../shared/system/messages/store/reducers';

interface Props {
  createRecord: (params: ICreateRecords, cb: any) => void;
  getRecordById: (payload: IGetRecordById, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
  userReducer: any;
}

const MyCasesEmailEditorWidget: React.FC<Props> = (props: Props) => {
  const { alertMessage } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const { selectedCase } = state;

  const [newEmailBody, setNewEmailBody] = useState<string>('');
  const [newEmailSubject, setNewEmailSubject] = useState<string>('');
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);

  const [fromEmailAddress, setFromEmailAddress] = useState<string>('');
  const [validationErrorMessage, setValidationErrorMessage] = useState<string>('');
  const [CCEmails, setCCEmails] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);

  const initializeEmailEditor = () => {
    const customerEmails = state.selectedCaseEmails?.filter(
      (elem) => elem.properties?.SenderType === 'CUSTOMER',
    );

    let lastCustomerEmail;
    if (customerEmails.length > 0) {
      lastCustomerEmail = customerEmails[customerEmails.length - 1];
      setNewEmailSubject(`Re: ${getProperty(lastCustomerEmail, 'Subject')}`);
      setFromEmailAddress(getProperty(lastCustomerEmail, 'To'));
    }
  };

  const toggleEmailEditor = () => {
    dispatch({ type: MY_CASES_TOGGLE_EMAIL_EDITOR, payload: null });
    setNewEmailBody('');

    setNewEmailSubject('');
    setValidationErrorMessage('');
    setIsSendingEmail(false);
    setCCEmails([]);
  };

  const validateEmail = () => {
    setValidationErrorMessage('');

    if (isEmailSubjectEmpty()) {
      setValidationErrorMessage('Subject is required');
    } else if (isEmailBodyEmpty()) {
      setValidationErrorMessage('Email body is required');
    } else {
      sendEmail();
    }
  };

  const sendEmail = async () => {
    try {
      setIsSendingEmail(true);

      // The id of the last email record in the case, will be the parent message id of the new email.
      // This means we are writing a reply. If no email exists, then this is the first/parent email in the case.
      let lastEmail = null;
      if (state.selectedCaseEmails.length > 0) {
        lastEmail = state.selectedCaseEmails[state.selectedCaseEmails.length - 1];
      }

      let formData = new FormData();
      formData.append(
        'properties',
        JSON.stringify({
          Subject: newEmailSubject,
          Message: newEmailBody,
          From: fromEmailAddress,
          To: getProperty(state.selectedCaseContact, 'EmailAddress'),
          Cc: CCEmails.length > 0 ? CCEmails.join(',') : undefined,
          Case: selectedCase?.id || null,
          ParentMessageId: lastEmail?.id,
        }),
      );

      // Add attachments
      if (attachments.length > 0) {
        attachments.forEach((file: File) => {
          formData.append('file', file);
        });
      }

      await httpPost(`NotificationModule/v1.0/email/support/send`, formData);

      setAttachments([]);
      setIsSendingEmail(false);
      toggleEmailEditor();
    } catch (error: any) {
      alertMessage({ body: error.response?.data?.message || error.message, type: 'error' });
      setAttachments([]);
      setIsSendingEmail(false);
    }
  };

  const isEmailBodyEmpty = () => {
    return newEmailBody.trim() === '';
  };

  const isEmailSubjectEmpty = () => {
    return newEmailSubject.trim() === '';
  };

  const isModalOpen = () => state.isComposingEmail;

  const onTemplateInsert = (template: string) => {
    setNewEmailBody(newEmailBody + template);
  };

  return (
    <>
      {/* Add Internal Comment Button */}
      <Button
        text="Email Customer"
        disabled={state.isAddingNote || state.isComposingEmail}
        outlined
        onClick={() => {
          dispatch({ type: MY_CASES_TOGGLE_EMAIL_EDITOR, payload: true });
          initializeEmailEditor();
        }}
        intent="primary"
        style={{
          boxShadow: '0px 0px 12px 0px rgba(0,0,0,0.10)',
          marginRight: 10,
          borderRadius: 5,
        }}
      />

      {/* Internal Comment editor */}
      {isModalOpen() && (
        <div className="newEmailContainer">
          <Section
            compact
            className="newEmailSection"
            title="Compose Email"
            icon={<i className="bi bi-envelope" />}
            rightElement={
              <Button
                minimal
                icon="cross"
                style={{ opacity: isSendingEmail ? 0.3 : 1 }}
                onClick={() => {
                  if (!isSendingEmail) {
                    toggleEmailEditor();
                  }
                }}
              />
            }
          >
            <div
              style={{
                background: 'white',
                height: 'calc(100vh - 593px)',
                overflowY: 'auto',
                padding: 8,
              }}
            >
              <Row align="middle" justify="space-between">
                {/* To: */}
                <Col span={2}>
                  <span style={{ color: '#606B85' }}>From: </span>
                </Col>
                <Col span={22} style={{ paddingLeft: 5 }}>
                  <TagInput
                    fill
                    addOnBlur
                    values={[fromEmailAddress]}
                    tagProps={{ minimal: true, intent: 'none', style: { borderRadius: 5 } }}
                    className="myCasesEmailTagInput"
                    onChange={(values: any[]) => {
                      setFromEmailAddress(values?.filter((elem) => elem?.length > 2)[0]);
                    }}
                    separator={','}
                    inputProps={{ autoFocus: true }}
                  />
                </Col>
              </Row>
              <Row align="middle" justify="space-between">
                {/* To: */}
                <Col span={1}>
                  <span style={{ color: '#606B85' }}>To: </span>
                </Col>
                <Col span={23} style={{ paddingLeft: 5 }}>
                  <Tag minimal intent="primary" style={{ borderRadius: 5 }}>
                    {state.selectedCaseContact?.title} (
                    {getProperty(state.selectedCaseContact, 'EmailAddress')})
                  </Tag>
                </Col>
              </Row>

              {/* Cc */}
              <Row align="middle">
                <Col span={1}>
                  <span style={{ color: '#606B85' }}>Cc: </span>
                </Col>
                <Col span={23} style={{ paddingLeft: 5 }}>
                  <TagInput
                    fill
                    addOnBlur
                    values={CCEmails}
                    tagProps={{ minimal: true, intent: 'none', style: { borderRadius: 5 } }}
                    className="myCasesEmailTagInput"
                    onChange={(values: any[]) => {
                      setCCEmails(values);
                    }}
                    separator={','}
                    inputProps={{ autoFocus: true }}
                  />
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>

              {/* Subject */}
              <Row align="middle">
                <Col>
                  <span style={{ color: '#606B85' }}>Subject: </span>
                </Col>
                <Col>
                  <InputGroup
                    value={newEmailSubject}
                    fill
                    style={{
                      boxShadow: 'none',
                      padding: 0,
                      color: 'black',
                      minWidth: 400,
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setNewEmailSubject(e.target.value);
                      setValidationErrorMessage('');
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <div
                    style={{
                      background: 'white',
                      height: 'calc(100vh - 715px)',
                      overflowY: 'auto',
                      padding: 5,
                    }}
                  >
                    <TextArea
                      intent="warning"
                      placeholder={'Write your email here'}
                      fill
                      autoFocus
                      value={newEmailBody}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setNewEmailBody(e.target.value);
                        setValidationErrorMessage('');
                      }}
                      style={{ height: '100%', boxShadow: 'none', padding: 0 }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div
              style={{
                background: 'white',
                borderTop: '1px solid #D9DADA',
              }}
            >
              <Row
                style={{
                  padding: 10,
                  height: 50,
                }}
                align="middle"
              >
                {/* Send button / Attach Button */}
                <Col span={8}>
                  <Row>
                    <Col>
                      <Button
                        intent="primary"
                        text="Send"
                        onClick={validateEmail}
                        disabled={isEmailBodyEmpty()}
                        loading={isSendingEmail}
                        style={{ borderRadius: 5 }}
                      />
                    </Col>
                    <Col style={{ marginRight: 8 }}>
                      <FileUploaderPopoverButton
                        disabled={isSendingEmail}
                        files={attachments}
                        setFiles={setAttachments}
                      />
                    </Col>
                    <Col>
                      <MessageTemplateHandler
                        context="EMAIL"
                        record={state.selectedCaseContact}
                        onTemplateSelected={onTemplateInsert}
                        disabled={isSendingEmail}
                      />
                    </Col>
                  </Row>
                </Col>

                {/* Error Validation */}
                <Col span={16} style={{ textAlign: 'right' }}>
                  <span style={{ color: 'red' }}>{validationErrorMessage}</span>
                </Col>
              </Row>
            </div>
          </Section>
        </div>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  createRecord: (params: ICreateRecords, cb: any) => dispatch(createRecordsRequest(params, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(MyCasesEmailEditorWidget);
