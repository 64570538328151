import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../..';
import {
  MY_CASES_ADD_EMAIL,
  MY_CASES_ADD_MESSAGE,
  MY_CASES_CLOSE_CONVERSATION,
} from '../../store/constants';
import { io } from 'socket.io-client';

interface Props {
  userReducer: any;
}

const SOCKET_URL = `${import.meta.env.VITE_ODIN_WEBSOCKET_URL}/ChatModule/TwilioChatWebsocket/v1.0`;

const MyCasesWebSockets: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;

  const { state, dispatch } = useContext(MyCasesContext);
  const [socket, setSocket] = useState<any>(undefined);

  let caseIdRef: any = useRef(undefined);
  let feedContainerRef: any = useRef(null);

  // Initialize WebSockets
  useEffect(() => {
    const s = io(SOCKET_URL, {
      path: '/ws/socket.io',
    });

    if (s) {
      setSocket(s);
    }
  }, []);

  // Mount WebSockets Event Listeners
  useEffect(() => {
    if (socket) {
      socket.on('connect', () =>
        console.log('%cdebug: [WS] Connected to WebSockets service', 'color:#bada55'),
      );
      socket.on(`case-event-${userReducer.user.id}`, handleIncomingEvent);
    }
    return () => {
      socket?.off('message-sent', handleIncomingEvent);
      socket?.disconnect();
    };
  }, [socket]);

  // Handle Selected Case Container Refs
  useEffect(() => {
    if (state.selectedCase?.id !== caseIdRef.current) {
      caseIdRef.current = state.selectedCase?.id;
    }
  }, [state.selectedCase, caseIdRef]);

  // Handle Feed Container Refs
  useEffect(() => {
    if (
      state.feedContainerRef?.current?.scrollHeight! !== feedContainerRef?.current?.scrollHeight
    ) {
      feedContainerRef.current = state.feedContainerRef?.current;
    } else {
    }
  }, [state.feedContainerRef?.current?.scrollHeight, feedContainerRef, state.selectedCase]);

  const isMessageForThisCase = (message: DbRecordEntityTransform) => {
    return message?.caseId === caseIdRef?.current;
  };

  // WebSockets Event Handlers Routing
  const handleIncomingEvent = (message: any) => {
    // console.log('debug: ANY EVENT', message);
    if (isMessageForThisCase(message)) {
      switch (message.type) {
        case 'MESSAGE_SENT':
          handleMessageSentEvent(message);
          break;
        case 'CONVERSATION_CLOSED':
          handleConversationClosedEvent(message);
          break;
        case 'EMAIL_RECEIVED':
          handleEmailReceivedEvent(message);
          break;
        default:
          console.log('debug: [WS] Unhandled message received', message);
          break;
      }
    }
  };

  // MESSAGE_SENT Event Handler
  const handleMessageSentEvent = (message: any) => {
    console.log('%cdebug: [WS] MESSAGE_SENT', 'color: #bada55', message);
    dispatch({ type: MY_CASES_ADD_MESSAGE, payload: message.data as DbRecordEntityTransform });

    // Check if own message by comparing from property with userReducer.user.email
    if (getProperty(message.data, 'From') === userReducer.user.email) {
      if (feedContainerRef) {
        feedContainerRef.current.scrollTo({
          top: feedContainerRef.current.scrollTop + feedContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }
  };

  // CONVERSATION_CLOSED Event Handler
  const handleConversationClosedEvent = (message: any) => {
    console.log('%cdebug: [WS] CONVERSATION_CLOSED', 'color: #bada55', message);
    dispatch({ type: MY_CASES_CLOSE_CONVERSATION, payload: true });
  };

  // EMAIL_RECEIVED Event Handler
  const handleEmailReceivedEvent = (message: any) => {
    console.log('%cdebug: [WS] EMAIL_RECEIVED', 'color: #bada55', message);
    dispatch({ type: MY_CASES_ADD_EMAIL, payload: message.data as DbRecordEntityTransform });
  };

  return <></>;
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesWebSockets);
