import { Button } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaColumnOptionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/option/schema.column.option.entity';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { MyCasesContext } from '../..';
import CommunicationsStatusTag from '../CommunicationsStatusTag';
import './styles.scss';
import MyCasesEditCaseButton from './MyCasesEditCaseButton';
import { connect } from 'react-redux';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../../../core/userInterface/store/actions';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';

interface Props {
  schema: SchemaEntity;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const CASE = 'Case';

const MyCasesHeader: React.FC<Props> = (props: Props) => {
  const { state } = useContext(MyCasesContext);
  const { schema, openDrawer } = props;
  const selectedCase: DbRecordEntityTransform | undefined = state.selectedCase;

  const getENUMOptionLabel = (colName: string, value: string) => {
    let response: string = '-';
    if (schema && colName && value) {
      const col: SchemaColumnEntity | undefined = schema.columns.find(
        (c: SchemaColumnEntity) => c.name === colName,
      );
      return (
        col?.options?.find((option: SchemaColumnOptionEntity) => option.value === value)?.label ||
        '-'
      );
    }
    return response;
  };

  return (
    <div className={`myCasesRightColumnHeader ${state.isSingleCaseMode ? 'singleCase' : ''}`}>
      <Row align="middle" style={{ height: 58, padding: '5px 10px' }} justify="space-between">
        <Col span={20}>
          <Row>
            {state.selectedCase && (
              <Col
                style={{
                  background: '#efefef',
                  height: 40,
                  fontWeight: 600,
                  borderRadius: 5,
                  fontSize: '1.2em',
                  padding: '9px 10px',
                }}
              >
                <span style={{ margin: 0 }}>Case #{selectedCase?.recordNumber}</span>
              </Col>
            )}

            {/* Source */}
            <Col style={{ padding: '0 20px', borderRight: '1px solid #D9DADA' }}>
              <span style={{ opacity: 0.5 }}>Source</span>
              <br />
              <span>{getProperty(selectedCase, 'Source') || '-'}</span>
            </Col>

            {/* Category */}
            <Col style={{ padding: '0 20px', borderRight: '1px solid #D9DADA' }}>
              <span style={{ opacity: 0.5 }}>Category</span>
              <br />
              <span>
                {selectedCase
                  ? getENUMOptionLabel('Category', getProperty(selectedCase, 'Category'))
                  : '-'}
              </span>
            </Col>

            {/* Subcategory */}
            <Col style={{ padding: '0 20px', borderRight: '1px solid #D9DADA' }}>
              <span style={{ opacity: 0.5 }}>Subcategory</span>
              <br />
              <span>
                {selectedCase
                  ? getENUMOptionLabel('SubCategory', getProperty(selectedCase, 'SubCategory'))
                  : '-'}
              </span>
            </Col>

            {/* Status */}
            <Col style={{ padding: '0 20px', borderRight: '1px solid #D9DADA' }}>
              <span style={{ opacity: 0.5 }}>Status</span>
              <br />
              {selectedCase ? <CommunicationsStatusTag status={selectedCase?.stage?.name} /> : '-'}
            </Col>

            {/* Created Date */}
            <Col style={{ padding: '0 20px', borderRight: '1px solid #D9DADA' }}>
              <span style={{ opacity: 0.5 }}>Created Date</span>
              <br />
              <span>
                {selectedCase ? dayjs(selectedCase?.createdAt).format('DD/MM/YYYY') : '-'}
              </span>
            </Col>
          </Row>
        </Col>

        {/* Buttons */}
        <Col span={4} style={{ textAlign: 'right' }}>
          {/* Edit Case */}
          {state.selectedCase && schema && <MyCasesEditCaseButton caseSchema={schema} />}

          {/* Open Case in Quick View */}
          {state.selectedCase && (
            <Button
              minimal
              icon={<i className="bi bi-eye" />}
              intent="primary"
              onClick={() => {
                openDrawer({
                  recordId: state.selectedCase!.id,
                  moduleName: SUPPORT_MODULE,
                  entityName: CASE,
                });
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(MyCasesHeader);
