import { Col, Row } from 'antd';
import React, { FC, useContext } from 'react';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import HeaderDetailView from '../../../../shared/components/HeaderDetailView';
import RecordStageStandardPipeline from '../../../../shared/components/RecordStageStandardPipeline';
import SchemaActionPageHeader from '../../../../shared/components/SchemaActions/SchemaActionPageHeader';
import MyCasesViewV2 from '../MyCasesViewV2';

interface Props {
  isQuickView?: boolean;
}

const CaseDetailView: FC<Props> = (props: Props) => {
  const { record, schema, pipeline } = useContext(DetailViewContext);
  const { isQuickView } = props;

  return (
    <>
      <Row>
        {/* Header */}
        <Col span={24}>
          <HeaderDetailView
            isQuickView={isQuickView}
            record={record!}
            extra={[
              // Non-Sequential Pipeline
              !pipeline?.isSequential && record && schema && (
                <RecordStageStandardPipeline record={record} pipeline={pipeline} />
              ),
              record && schema && (
                <SchemaActionPageHeader
                  moduleName={schema.moduleName}
                  entityName={schema.entityName}
                  record={record}
                  launcherType="INLINE"
                />
              ),
            ]}
          />
        </Col>

        {/* Case View */}
        <Col span={24} style={{ padding: 15 }}>
          <MyCasesViewV2 defaultCase={record} />
        </Col>
      </Row>
    </>
  );
};

export default CaseDetailView;
