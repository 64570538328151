import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../../..';
import DOMPurify from 'dompurify';

import './styles.scss';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import MyCaseAttachmentList from '../MyCaseAttachmentList';

interface Props {
  message: any;
  userReducer: any;
  caseSchema: SchemaEntity;
}

const MyCasesMessage: React.FC<Props> = (props: Props) => {
  const { message, userReducer, caseSchema } = props;
  const { state, dispatch } = useContext(MyCasesContext);

  const messageBody = getProperty(message, 'Body');
  const sanitizedMessage = DOMPurify.sanitize(messageBody);

  let files: string[] = getProperty(message, 'Files')
    ? getProperty(message, 'Files').split(',')
    : [];

  return (
    <Row
      key={message.hourSent}
      justify={`${getProperty(message, 'From') === userReducer.user.email ? 'end' : 'start'}`}
      style={{ padding: '18px 22px 18px 18px' }}
    >
      {/* CLIENT Message */}
      {getProperty(message, 'From') !== userReducer.user.email ? (
        <Col>
          <Row>
            {/* Message */}
            <Col span={24}>
              <Col className="myCasesClientMessage">
                <span dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
              </Col>
            </Col>

            {/* Client Name and Timestamp */}
            <Col span={24} style={{ color: '#727c93' }}>
              <Row justify="space-between">
                <Col>
                  <span>{getProperty(message, 'From') || 'New Account'}</span>
                </Col>
                <Col>
                  <span>{dayjs(message.createdAt).format('h:mm:ss A')}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ) : (
        // USER Message
        <Col>
          <Row>
            {/* Message */}
            <Col span={24} className="myCasesUserMessage">
              <span dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
              {/* File Attachments */}
              {files.length > 0 && (
                <Col span={24} style={{ marginTop: 15, marginBottom: 5 }}>
                  <MyCaseAttachmentList fileIds={files} />
                </Col>
              )}
            </Col>
            {/* Timestamp */}
            <Col span={24}>
              <Col style={{ color: '#727c93' }}>
                <Row justify="end">
                  <Col>
                    <span>{dayjs(message.createdAt).format('h:mm:ss A')}</span>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesMessage);
