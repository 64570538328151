import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { Button } from '@blueprintjs/core';

interface Props {
  text: string;
  maxLetters: number;
}

const CollapsibleText: React.FC<Props> = (props: Props) => {
  const { maxLetters, text } = props;
  const [show, setShow] = useState(false);
  return (
    <Row>
      <Col span={24}>
        {text.length < maxLetters ? text : show ? text : text.slice(0, maxLetters) + '...'}
      </Col>
      {text.length > maxLetters && (
        <Col span={24} style={{ marginTop: 5 }}>
          <Button
            small
            intent="primary"
            minimal
            onClick={() => setShow(!show)}
            style={{ padding: '0 3px' }}
          >
            {show ? 'Show less' : 'Show more'}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default CollapsibleText;
