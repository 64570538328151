import { Divider, NonIdealState, Spinner, Tab, Tabs } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { MyCasesContext } from '../..';
import './styles.scss';
import MyCasesContactLookup from '../MyCasesContactDrawer/MyCasesContactLookup';
import MyCasesContactDetails from '../MyCasesContactDrawer/MyCasesContactDetails';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import RecordProperties from '../../../../../../../core/records/components/RecordProperties';
import GroupDetails from '../../../../../../../shared/components/GroupDetails';
import { MetadataLinks } from '@d19n/temp-fe-d19n-models/dist/schema-manager/metadata.links';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '../../../../../../../shared/utilities/recordHelpers';

interface Props {
  schema: SchemaEntity;
}

const MyCasesContactDrawerTabbed: React.FC<Props> = (props: Props) => {
  const { schema } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const [selectedTab, setSelectedTab] = useState<'Contact' | 'Details'>('Contact');
  const { selectedCaseContact } = state;

  const renderLinks = () => {
    const record = state.selectedCase;
    if (record && record.links && record.links?.length! > 0) {
      return record.links.map((link: MetadataLinks, index: number) => (
        <Row
          justify="space-between"
          align="top"
          key={`metadataRow${link.id}`}
          style={{ marginBottom: 10 }}
        >
          <Col key={`metadataCol1${link.id}`}>{link.entity?.split(':')[1]}</Col>
          <Col key={`metadataCol2${link.id}`}>
            <Link key={`metadataLink${link.id}`} target="_blank" to={getBrowserPath(link as any)}>
              {link.title}
            </Link>
          </Col>
          {
            // if last link add divider
            index === record?.links?.length! - 1 ? null : (
              <Col span={24} key={`metadataDivider${link.id}`} style={{ marginTop: 10 }}>
                <Divider style={{ margin: 0, padding: 0 }} />
              </Col>
            )
          }
        </Row>
      ));
    } else {
      return <span>No links</span>;
    }
  };

  return (
    <Col
      span={8}
      className={`myCasesContactDetailsCol ${state.isSingleCaseMode ? 'singleCase' : ''}`}
    >
      <Row style={{ padding: 10 }}>
        <Col span={24}>
          <Tabs selectedTabId={selectedTab} onChange={(e: any) => setSelectedTab(e)}>
            <Tab
              id="Contact"
              title="Contact"
              style={{ marginTop: 0 }}
              panel={
                <Col
                  span={24}
                  style={{
                    height: `calc(100vh - 250px)`,
                    overflowY: 'auto',
                  }}
                >
                  {/* Initial Search for Associated Contact */}
                  {state.isSearchingForAssociatedContact && (
                    <div style={{ marginTop: 240 }}>
                      <NonIdealState icon={<Spinner size={40} />} title="Searching for Contact" />
                    </div>
                  )}

                  {/* Missing Contact */}
                  {!selectedCaseContact &&
                    !state.isContactPanelOpen &&
                    !state.isSearchingForAssociatedContact && <MyCasesContactLookup />}

                  {/* Existing Contact */}
                  {selectedCaseContact && !state.isSearchingForAssociatedContact && (
                    <MyCasesContactDetails contact={state.selectedCaseContact!} />
                  )}
                </Col>
              }
            />
            <Tab
              id="Details"
              title="Details"
              panel={
                <Col
                  span={24}
                  style={{
                    height: `calc(100vh - 320px)`,
                    overflowY: 'auto',
                  }}
                >
                  <Row style={{ padding: 10 }}>
                    {/* Record Properties */}
                    <Col span={24}>
                      <h3 style={{ marginTop: 0, marginBottom: 15 }}>Record Details</h3>
                      <RecordProperties record={state.selectedCase!} schema={schema} />
                    </Col>

                    {/* Group Details */}
                    <Col span={24} style={{ marginTop: 25 }}>
                      <h3 style={{ marginTop: 0, marginBottom: 15 }}>Groups</h3>
                      <GroupDetails record={state.selectedCase} />
                    </Col>

                    {/* Record Links */}
                    <Col span={24} style={{ marginTop: 25 }}>
                      <h3 style={{ marginTop: 0, marginBottom: 20 }}>Record Links</h3>
                    </Col>

                    <Col span={24}>{renderLinks()}</Col>
                  </Row>
                </Col>
              }
            />
          </Tabs>
        </Col>
      </Row>
    </Col>
  );
};
export default MyCasesContactDrawerTabbed;
