import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { BlueprintNavigation } from '../../../../../../shared/components/BlueprintPagination';
import { Button, InputGroup, Spinner, Tooltip } from '@blueprintjs/core';
import OdinTable from '../../../../../../shared/components/OdinTable';
import { connect } from 'react-redux';
import { displayMessage } from '../../../../../../../shared/system/messages/store/reducers';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '../../../../../../../shared/utilities/recordHelpers';
import './styles.scss';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../../../core/userInterface/store/actions';
import { httpPost } from '../../../../../../../shared/http/requests';
import { toSentenceCase } from '../../../../../../../shared/utilities/stringHelpers';
import CommunicationsStatusTag from '../../../MyCasesViewV2/components/CommunicationsStatusTag';
import { getCaseContactFromRecord } from '../../helpers';
import { SupportDashboardContext } from '../../index';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import dayjs from 'dayjs';
import AssignAgentToCaseDropdown from './AssignAgentToCaseDropdown';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const CASE = 'Case';

interface Props {
  alertMessage: (params: { body: string; type: string }) => void;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

interface ITableDataItem {
  key: string;
  caseNumber: ReactNode;
  contact?: ReactNode;
  age: ReactNode;
  source: string;
  category: string;
  SLA: ReactNode;
  agent: ReactNode;
  action: ReactNode;
}

const CaseManagementListView: FC<Props> = (props: Props) => {
  const { alertMessage, openDrawer } = props;
  const { caseSchema } = useContext(SupportDashboardContext);

  // Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);

  const [caseList, setCaseList] = useState<DbRecordEntityTransform[]>([]);

  const [inputQuery, setInputQuery] = useState<string>('');
  const [debouncedQuery, setDebouncedQuery] = useState<string>(inputQuery);

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Set a timeout to update the debounced query after 500ms
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(inputQuery);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [inputQuery]);

  useEffect(() => {
    if (caseSchema) {
      getCaseList(debouncedQuery);
    }
  }, [currentPage, caseSchema, debouncedQuery]);

  const getCaseList = async (stringQuery: string) => {
    try {
      if (caseSchema) {
        setIsRefreshing(true);
        setIsLoading(true);
        // Search 2.0
        let query = {
          returnQueryPlan: false,
          query: {
            entity: 'SupportModule:Case',
            type: 'and',
            value: stringQuery
              ? [
                  {
                    columnName: 'RecordNumber',
                    operator: 'anyTerm',
                    value: stringQuery,
                  },
                ]
              : [],
            returnProperties: [
              'id',
              'title',
              'recordNumber',
              'createdAt',
              'stage',
              'entity',
              'caseTeam',
              'caseOwner',
              'caseContact',
              'properties.SLA',
              'properties.OwnerId',
              'properties.TeamId',
              'properties.ContactId',
              'properties.Category',
              'properties.Source',
              'properties.Channel',
            ],
            sort: {
              createdAt: {
                order: 'desc',
              },
            },
            pageSize: pageSize,
            pageNumber: currentPage - 1,
          },
        };

        return httpPost(`SupportModule/v2.0/records/search`, query).then((res) => {
          const records = res?.data?.data?.records || [];
          console.log('%cdebug: Search Results', 'color:limegreen', res?.data);

          const totalRecords = res?.data?.data?.totalRecords || 0;
          setTotalRecordsCount(totalRecords);
          console.log('debug: total records', totalRecords);

          console.log('debug: Case Table Data', records);
          setIsRefreshing(false);
          setIsLoading(false);
          setCaseList(records);
        });
      }
    } catch (e: any) {
      setIsRefreshing(false);
      setIsLoading(false);
      alertMessage({
        body: 'Failed to fetch case list.',
        type: 'error',
      });
    }
  };

  const updateRecordInCaseList = (record: DbRecordEntityTransform) => {
    const updatedCaseList = caseList.map((item: DbRecordEntityTransform) => {
      if (item.id === record.id) {
        return record;
      } else {
        return item;
      }
    });
    setCaseList(updatedCaseList);
  };

  const tableData: ITableDataItem[] = caseList.map((item: DbRecordEntityTransform) => {
    return {
      key: item.id,
      caseNumber: (
        <Link target="_blank" key={item.id} to={getBrowserPath(item)}>
          {item.recordNumber}
        </Link>
      ),
      contact: (
        <Row>
          <Col span={20}>{getCaseContactFromRecord(item)}</Col>
          <Col span={4}>
            {item.caseContact?.length! > 0 ? (
              <Link to={`/CrmModule/Contact/${item.caseContact[0].id}`} target="_blank">
                <Button
                  small
                  intent="primary"
                  minimal
                  icon={<i className="bi bi-box-arrow-up-right" />}
                />
              </Link>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      ),
      age: (
        <Tooltip
          hoverOpenDelay={1000}
          position="top"
          content={String(dayjs(item.createdAt).format('DD/MM/YYYY HH:mm:ss'))}
        >
          <span style={{ cursor: 'pointer' }} key={`age${item.id}`}>
            {dayjs(item.createdAt).fromNow()}
          </span>
        </Tooltip>
      ),
      source: `${
        getProperty(item, 'Source') ? toSentenceCase(getProperty(item, 'Source')) : 'Unknown'
      } → ${
        getProperty(item, 'Channel') ? toSentenceCase(getProperty(item, 'Channel')) : 'Unknown'
      }`,
      category: getProperty(item, 'Category') || '',
      SLA: getProperty(item, 'SLA') || '',
      agent: (
        <Row align="middle" key={`assignCaseRow1${item.id}`}>
          <Col span={24} key={`assignCaseCol1${item.id}`}>
            <AssignAgentToCaseDropdown caseRecord={item} onRecordUpdate={updateRecordInCaseList} />
          </Col>
        </Row>
      ),
      status: (
        <Row align="middle" justify="center">
          <Col>
            <CommunicationsStatusTag status={item.stage?.name} />
          </Col>
        </Row>
      ),
      action: (
        <Row align="middle" justify="center">
          <Col>
            <Button
              key={item.id}
              intent="primary"
              minimal
              onClick={() => {
                openDrawer({
                  recordId: item.id,
                  moduleName: SUPPORT_MODULE,
                  entityName: CASE,
                });
              }}
              icon={<i className="bi bi-eye" />}
            />
          </Col>
        </Row>
      ),
    };
  });

  return (
    <>
      <Row>
        <Col span={20}>
          <h1 style={{ margin: 0 }}>Cases</h1>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <InputGroup
            // disabled={isLoading}
            type="search"
            placeholder="Search Case Number"
            onChange={(e: any) => setInputQuery(e.target.value)}
            intent={inputQuery.length > 0 ? 'primary' : 'none'}
            leftIcon={isRefreshing && inputQuery.length > 0 ? null : 'search'}
            leftElement={
              isRefreshing && inputQuery.length > 0 ? (
                <Spinner size={20} style={{ padding: 5 }} />
              ) : undefined
            }
          />
        </Col>
      </Row>
      {/* Table */}
      <div style={{ marginTop: 30, overflowX: 'auto' }}>
        <OdinTable
          isLoading={isLoading}
          isRefreshing={isRefreshing}
          height="calc(100vh - 245px)"
          data={tableData}
          columns={[
            {
              key: 'caseNumber',
              title: 'Case #',
              width: 2,
              // filterable: true,
            },
            {
              key: 'contact',
              title: 'Contact',
              width: 3,
              // searchable: true,
            },
            {
              key: 'age',
              title: 'Age',
              width: 3,
            },
            {
              key: 'source',
              title: 'Source',
              width: 3,
              // filterable: true,
            },
            {
              key: 'category',
              title: 'Category',
              width: 3,
              // filterable: true,
            },
            {
              key: 'SLA',
              title: 'SLA',
              width: 2,
              align: 'center',
              // sortable: true,
            },
            {
              key: 'agent',
              title: 'Agent',
              width: 4,
              // searchable: true,
              align: 'center',
              hideDivider: true,
            },
            {
              key: 'status',
              title: 'Status',
              width: 3,
              align: 'center',
            },
            {
              key: 'action',
              title: '',
              width: 1,
            },
          ]}
        />
      </div>

      {/* Pagination */}
      <Row style={{ background: 'white' }}>
        <div style={{ padding: '10px 0' }}>
          <BlueprintNavigation
            totalCount={totalRecordsCount}
            currentPage={currentPage}
            pageSize={pageSize}
            onPaginate={setCurrentPage}
            // disabled={caseList.length <= pageSize}
          />
        </div>
      </Row>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(CaseManagementListView);
