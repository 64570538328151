import { Button, Callout, InputGroup, NonIdealState } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../..';
import { httpPost } from '../../../../../../../shared/http/requests';
import {
  MY_CASES_SET_FEED_BOTTOM_REF,
  MY_CASES_SET_FEED_CONTAINER_REF,
} from '../../store/constants';
import MyCasesEmailEditorWidget from '../MyCasesEmailEditorWidget';
import MyCasesNoteEditorWidget from '../MyCasesNoteEditorWidget';
import MyCasesCall from './MyCasesCall';
import MyCasesEmail from './MyCasesEmail';
import MyCasesMessage from './MyCasesMessage';
import MyCasesNote from './MyCasesNote';
import './styles.scss';
import MessageTemplateHandler from '../../../../../../shared/components/MessageTemplateHandler';
import { displayMessage } from '../../../../../../../shared/system/messages/store/reducers';
import FileUploaderPopoverButton from '../../../../../../shared/components/FileUploaderPopoverButton';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getOdinSchemaByEntity } from '../../../../../../../shared/utilities/schemaHelpers';

interface Props {
  userReducer: any;
  alertMessage: (params: { body: string; type: string }) => void;
}

const MyCasesConversationFeed: React.FC<Props> = (props: Props) => {
  const { userReducer, alertMessage } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const selectedCase: DbRecordEntityTransform | undefined = state.selectedCase;
  const notes: DbRecordEntityTransform[] = state.selectedCaseNotes;
  const messages: DbRecordEntityTransform[] = state.selectedCaseMessages;
  const emails: DbRecordEntityTransform[] = state.selectedCaseEmails;
  const calls: DbRecordEntityTransform[] = state.selectedCaseCalls;
  const { isSingleCaseMode } = state;

  const inputRef = useRef<any>(null);

  const [messageText, setMessageText] = useState<string>('');
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const feedContainerRef = useRef<HTMLDivElement | null>(null);
  const feedBottomRef = useRef<HTMLDivElement | null>(null);

  const [caseSchema, setCaseSchema] = useState<SchemaEntity | undefined>(undefined);

  useEffect(() => {
    getCaseSchema();
  }, []);

  // This should refocus the input when the user sends a message
  useEffect(() => {
    if (!isSendingMessage) {
      inputRef.current?.focus();
    }
  }, [isSendingMessage]);

  const getCaseSchema = async () => {
    const schema = await getOdinSchemaByEntity('SupportModule', 'Case');
    setCaseSchema(schema);
  };

  useEffect(() => {
    if (state.selectedCase && messageText.length > 0) {
      setMessageText('');
      setIsSendingMessage(false);
    }
  }, [state.selectedCase]);

  useEffect(() => {
    if (feedBottomRef.current) {
      dispatch({ type: MY_CASES_SET_FEED_BOTTOM_REF, payload: feedBottomRef });
    }
  }, [feedBottomRef]);

  useEffect(() => {
    if (feedContainerRef.current) {
      dispatch({ type: MY_CASES_SET_FEED_CONTAINER_REF, payload: feedContainerRef });
    }
  }, [feedContainerRef]);

  // We need to merge the conversations and messages into a single feed, and sort by createdBy date
  let Feed: DbRecordEntityTransform[] = [];
  if (messages.length > 0) {
    Feed = Feed.concat(messages);
  }
  if (notes && notes.length > 0) {
    Feed = Feed.concat(notes);
  }
  if (emails && emails.length > 0) {
    Feed = Feed.concat(emails);
  }
  if (calls && calls.length > 0) {
    Feed = Feed.concat(calls);
  }
  Feed.sort((a, b) => {
    return dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf();
  });

  const isRecordMessage = (record: DbRecordEntityTransform) => {
    return record.entity && record.entity.indexOf('NotificationModule:Message') > -1;
  };

  const isRecordNote = (record: DbRecordEntityTransform) => {
    return record.entity && record.entity.indexOf('SupportModule:Note') > -1;
  };

  const isRecordEmail = (record: DbRecordEntityTransform) => {
    return record.entity && record.entity.indexOf('NotificationModule:Email') > -1;
  };

  const isRecordCall = (record: DbRecordEntityTransform) => {
    return record.entity && record.entity.indexOf('NotificationModule:Call') > -1;
  };

  const smoothScrollFeedToBottom = () => {
    const feedContainer = state.feedContainerRef?.current;
    if (feedContainer) {
      feedContainer.scrollTo({
        top: feedContainer.scrollTop + feedContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const sendChatMessage = async () => {
    if (messageText.length && state.selectedCaseConversation) {
      setIsSendingMessage(true);

      let formData = new FormData();

      formData.append('message', messageText);
      formData.append('from', userReducer.user?.firstname);
      if (attachments.length > 0) {
        attachments.forEach((file: File) => {
          formData.append('files', file);
        });
      }

      try {
        await httpPost(
          `ChatModule/v1.0/gateway/conversation/${state.selectedCaseConversation.id}/message/send`,
          formData,
        );

        setIsSendingMessage(false);
        setMessageText('');
        setAttachments([]);
      } catch (error: any) {
        alertMessage({
          body: error.response?.data?.message || error.message,
          type: 'error',
        });
        setIsSendingMessage(false);
      }
    }
  };

  const doesSelectedContactHaveEmail = (): boolean => {
    return !!getProperty(state.selectedCaseContact, 'EmailAddress');
  };

  const caseIsSolvedOrClosed = (): boolean => {
    return (
      state.selectedCase?.stage?.name === 'Closed' || state.selectedCase?.stage?.name === 'Solved'
    );
  };

  const conversationIsClosed = (): boolean => {
    return getProperty(state.selectedCaseConversation, 'Status') === 'CLOSED';
  };

  const renderEmail = (email: DbRecordEntityTransform) => {
    return (
      <div style={{ marginLeft: 15 }}>
        <Row>
          <Col span={24}>
            <MyCasesEmail email={email} />
          </Col>
        </Row>
      </div>
    );
  };

  const onTemplateInsert = (template: string) => {
    setMessageText(messageText + template);
  };

  const caseIsOwnedByCurrentUser = (): boolean => {
    return getProperty(state.selectedCase, 'OwnerId') === userReducer.user.id;
  };

  return (
    <Col
      span={state.isContactPanelOpen ? 23 : 16}
      style={{
        background: 'white',
        height: `calc(100vh - ${state.isSingleCaseMode ? '240px' : '180px'})`,
        borderLeft: '1px solid #D9DADA',
      }}
    >
      <Row
        style={{
          backgroundColor: 'white',
          height: `calc(100vh - ${isSingleCaseMode ? '300px' : '240px'})`,
        }}
      >
        {/* No Selected Case */}
        {!state.selectedCase && (
          <Col span={24} style={{ textAlign: 'center', padding: 20 }}>
            <NonIdealState
              icon="chat"
              title="Select a Case"
              description="Select a case from the list to view the conversation"
            />
          </Col>
        )}

        {/* Selected Case - Show Feed */}
        <Col
          ref={feedContainerRef}
          span={24}
          style={{
            overflowY: 'auto',
            height: '100%',
            alignContent: 'flex-end',
            paddingTop: 10,
            display: !state.selectedCase ? 'none' : 'block',
            boxShadow: 'inset 0px 10px 9px -10px #D9DADA',
          }}
        >
          {Feed.map((item: DbRecordEntityTransform, i: number) => {
            if (isRecordMessage(item)) {
              return <MyCasesMessage message={item} caseSchema={caseSchema!} />;
            } else if (isRecordNote(item)) {
              return <MyCasesNote note={item} />;
            } else if (isRecordCall(item)) {
              return <MyCasesCall call={item} />;
            } else if (isRecordEmail(item)) {
              return renderEmail(item);
            } else return <></>;
          })}

          {/* Closed Conversation Message */}
          {conversationIsClosed() && (
            <Row style={{ padding: 15 }}>
              <Col span={24}>
                <Callout
                  style={{ borderRadius: 10 }}
                  intent="warning"
                  icon="info-sign"
                  title="Conversation Closed"
                >
                  This conversation is closed. You can no longer send messages.
                </Callout>
              </Col>
            </Row>
          )}

          {selectedCase && (
            <Row style={{ padding: 15, marginRight: 10 }} justify="end">
              <Col>
                {/* Email Customer Button */}
                {state.selectedCaseContact && doesSelectedContactHaveEmail() && (
                  <MyCasesEmailEditorWidget />
                )}
                {/* Add Internal Comment */}
                <MyCasesNoteEditorWidget />
              </Col>
            </Row>
          )}

          {/* Feed bottom */}
          <div id="bottomRef" ref={feedBottomRef} />

          {state.isScrollDownButtonVisible && (
            <Row style={{ bottom: 15, left: 15, position: 'sticky', paddingRight: 20 }}>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button
                  icon="double-chevron-down"
                  onClick={smoothScrollFeedToBottom}
                  outlined
                  style={{
                    borderRadius: 50,
                    background: 'white',
                  }}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row style={{ borderTop: '1px solid #D9DADA' }} align="middle">
        <Col span={24}>
          <Row style={{ margin: 10 }}>
            <Col sm={15} md={15} xl={17} xxl={21}>
              <InputGroup
                inputRef={inputRef}
                style={{ border: 'none', boxShadow: 'none', borderRadius: 5 }}
                large
                type="text"
                placeholder={
                  caseIsOwnedByCurrentUser()
                    ? 'Chat text'
                    : 'Chat is available only for case owners'
                }
                value={messageText}
                disabled={
                  !state.selectedCase ||
                  !state.selectedCaseConversation ||
                  !caseIsOwnedByCurrentUser() ||
                  isSendingMessage ||
                  conversationIsClosed() ||
                  caseIsSolvedOrClosed()
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setMessageText(e.target.value)
                }
                // If enter key is pressed, send the message
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    sendChatMessage();
                  }
                }}
              />
            </Col>
            <Col sm={9} md={9} xl={7} xxl={3} style={{ textAlign: 'right' }}>
              <MessageTemplateHandler
                context="MESSAGE"
                large
                record={state.selectedCaseContact}
                onTemplateSelected={onTemplateInsert}
                disabled={
                  !state.selectedCase ||
                  !state.selectedCaseConversation ||
                  isSendingMessage ||
                  conversationIsClosed() ||
                  caseIsSolvedOrClosed()
                }
              />

              <FileUploaderPopoverButton
                large={true}
                disabled={
                  !state.selectedCase ||
                  !state.selectedCaseConversation ||
                  isSendingMessage ||
                  conversationIsClosed() ||
                  caseIsSolvedOrClosed()
                }
                files={attachments}
                setFiles={setAttachments}
              />
              <Button
                intent="primary"
                disabled={
                  !messageText.length || conversationIsClosed() || !caseIsOwnedByCurrentUser()
                }
                text="Send"
                loading={isSendingMessage}
                onClick={sendChatMessage}
                large
                style={{ borderRadius: 5, marginLeft: 8 }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(MyCasesConversationFeed);
