import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { connect } from 'react-redux';
import {
  ISearchRecords,
  searchRecordsRequest,
} from '../../../../../../../core/records/store/actions';
import { displayMessage } from '../../../../../../../shared/system/messages/store/reducers';
import CaseManagementDashboardStatCard from './CaseManagementDashboardStatCard';
import CaseManagementDashboardChart from './CaseManagementDashboardChart';
import { DUMMY_STAGES } from './types';
import { httpPost } from '../../../../../../../shared/http/requests';
import dayjs from 'dayjs';

interface Props {
  searchRecords: (params: ISearchRecords, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const CaseManagementDashboard: FC<Props> = (props: Props) => {
  const [totalCallCases, setTotalCallCases] = React.useState<number>(0);
  const [callStageAggregation, setCallStageAggregation] = React.useState<any[]>([]);

  const [totalConversationCases, setTotalConversationCases] = React.useState<number>(0);
  const [conversationStageAggregation, setConversationStageAggregation] = React.useState<any[]>([]);

  const [totalEmailCases, setTotalEmailCases] = React.useState<number>(0);
  const [emailStageAggregation, setEmailStageAggregation] = React.useState<any[]>([]);

  const [totalTrustPilotCases, setTotalTrustPilotCases] = React.useState<number>(0);
  const [trustPilotStageAggregation, setTrustPilotStageAggregation] = React.useState<any[]>([]);

  const [totalFacebookCases, setTotalFacebookCases] = React.useState<number>(0);
  const [facebookStageAggregation, setFacebookStageAggregation] = React.useState<any[]>([]);

  React.useEffect(() => {
    fetchCalls();
    fetchConversations();
    fetchEmails();

    fetchFacebookPagePostCases();
    fetchTrustPilotCases();
  }, []);

  const fetchCalls = () => {
    httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: 'CALL',
          },
          {
            columnName: 'UpdatedAt',
            operator: 'gte',
            value: dayjs().startOf('day').format('YYYY-MM-DD'),
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { totalRecords, aggregations } = result.data.data;

      setTotalCallCases(totalRecords);

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
          (bucket: { key: string; doc_count: number }) => ({
            name: bucket.key,
            value: bucket.doc_count,
          }),
        );

        const stageAggsMap = new Map(
          stageAggs.map((stage: { name: string; value: number }) => [stage.name, stage]),
        );

        const filteredDummyStages = DUMMY_STAGES.filter(
          (dummyStage) => !stageAggsMap.has(dummyStage.name),
        );

        const mergedArray = [...stageAggs, ...filteredDummyStages];

        setCallStageAggregation(mergedArray);
      }
    });
  };

  const fetchConversations = () => {
    httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: 'WEB_CHAT',
          },
          {
            columnName: 'UpdatedAt',
            operator: 'gte',
            value: dayjs().startOf('day').format('YYYY-MM-DD'),
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { totalRecords, aggregations } = result.data.data;

      setTotalConversationCases(totalRecords);

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
          (bucket: { key: string; doc_count: number }) => ({
            name: bucket.key,
            value: bucket.doc_count,
          }),
        );

        const stageAggsMap = new Map(
          stageAggs.map((stage: { name: string; value: number }) => [stage.name, stage]),
        );

        const filteredDummyStages = DUMMY_STAGES.filter(
          (dummyStage) => !stageAggsMap.has(dummyStage.name),
        );

        const mergedArray = [...stageAggs, ...filteredDummyStages];

        setConversationStageAggregation(mergedArray);
      }
    });
  };

  const fetchEmails = () => {
    httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Source',
            operator: 'eq',
            value: 'EMAIL',
          },
          {
            columnName: 'UpdatedAt',
            operator: 'gte',
            value: dayjs().startOf('day').format('YYYY-MM-DD'),
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { totalRecords, aggregations } = result.data.data;

      setTotalEmailCases(totalRecords);

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
          (bucket: { key: string; doc_count: number }) => ({
            name: bucket.key,
            value: bucket.doc_count,
          }),
        );

        const stageAggsMap = new Map(
          stageAggs.map((stage: { name: string; value: number }) => [stage.name, stage]),
        );

        const filteredDummyStages = DUMMY_STAGES.filter(
          (dummyStage) => !stageAggsMap.has(dummyStage.name),
        );

        const mergedArray = [...stageAggs, ...filteredDummyStages];

        setEmailStageAggregation(mergedArray);
      }
    });
  };

  const fetchFacebookPagePostCases = () => {
    httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: 'FACEBOOK_PAGE_POST',
          },
          {
            columnName: 'UpdatedAt',
            operator: 'gte',
            value: dayjs().startOf('day').format('YYYY-MM-DD'),
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { totalRecords, aggregations } = result.data.data;

      setTotalFacebookCases(totalRecords);

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
          (bucket: { key: string; doc_count: number }) => ({
            name: bucket.key,
            value: bucket.doc_count,
          }),
        );

        const stageAggsMap = new Map(
          stageAggs.map((stage: { name: string; value: number }) => [stage.name, stage]),
        );

        const filteredDummyStages = DUMMY_STAGES.filter(
          (dummyStage) => !stageAggsMap.has(dummyStage.name),
        );

        const mergedArray = [...stageAggs, ...filteredDummyStages];

        setFacebookStageAggregation(mergedArray);
      }
    });
  };

  const fetchTrustPilotCases = () => {
    httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: 'TRUSTPILOT_SERVICE_REVIEW',
          },
          {
            columnName: 'UpdatedAt',
            operator: 'gte',
            value: dayjs().startOf('day').format('YYYY-MM-DD'),
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { totalRecords, aggregations } = result.data.data;

      setTotalTrustPilotCases(totalRecords);

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
          (bucket: { key: string; doc_count: number }) => ({
            name: bucket.key,
            value: bucket.doc_count,
          }),
        );

        const stageAggsMap = new Map(
          stageAggs.map((stage: { name: string; value: number }) => [stage.name, stage]),
        );

        const filteredDummyStages = DUMMY_STAGES.filter(
          (dummyStage) => !stageAggsMap.has(dummyStage.name),
        );

        const mergedArray = [...stageAggs, ...filteredDummyStages];

        setTrustPilotStageAggregation(mergedArray);
      }
    });
  };

  return (
    <>
      {/* Header */}
      <Row>
        <Col span={24}>
          <h1 style={{ margin: 0 }}>Dashboard</h1>
        </Col>
      </Row>
      {/* Dashboard */}
      <Row
        style={{
          marginTop: 30,
          height: 'calc(100vh - 140px)',
          overflowY: 'auto',
          alignContent: 'flex-start',
        }}
      >
        {/* SLA Score Chart */}
        <CaseManagementDashboardChart />

        {/* Tickets Stat */}

        {/* Calls Stat */}
        <CaseManagementDashboardStatCard
          title="Calls"
          topStats={[{ title: 'Total Cases', value: totalCallCases }]}
          properties={callStageAggregation}
        />

        {/* Chats Stat */}
        <CaseManagementDashboardStatCard
          title="Chats"
          topStats={[{ title: 'Total Cases', value: totalConversationCases }]}
          properties={conversationStageAggregation}
        />

        {/* Email Stat */}
        <CaseManagementDashboardStatCard
          title="Email"
          topStats={[{ title: 'Total Cases', value: totalEmailCases }]}
          properties={emailStageAggregation}
        />

        {/* Social Media */}
        <CaseManagementDashboardStatCard
          title="Social Media"
          topStats={[{ title: 'Total Cases', value: totalFacebookCases }]}
          properties={facebookStageAggregation}
        />

        {/* Social Media */}
        <CaseManagementDashboardStatCard
          title="TrustPilot"
          topStats={[{ title: 'Total Cases', value: totalTrustPilotCases }]}
          properties={trustPilotStageAggregation}
        />
      </Row>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CaseManagementDashboard);
