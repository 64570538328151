import { useState, useEffect } from "react";
import { Card, CardList, Classes, Icon, InputGroup, Section, SectionCard, Spinner, Tag } from "@blueprintjs/core"
import { v4 as uuidV4 } from 'uuid';

import { SchemaActionEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity";
import { PipelineEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity";
import { SchemaEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity";

import { httpGet, httpPost, httpPut } from '../../../../../../../../shared/http/requests';
import { displayMessage } from '../../../../../../../../shared/system/messages/store/reducers';
import { initializeSharedForm } from "../../../../../../../../shared/components/SharedForm/store/actions";
import FormModal, {
  FormReducerSubmitEvt,
} from '../../../../../../../../shared/components/SharedForm/SharedFormModal';

import { useAction } from '../../../../../../../../containers/ProjectModule/hooks/useAction';
import { getErrorMessage } from '../../../../../utils/errors';

import { SchemaDetailsProps } from "../types"
import { Empty } from "../Empty";

import { getFormFields } from "./formFields";
import SchemaActionConfiguration from "./SchemaActionConfiguration";

const uuid = uuidV4();

const getActionType = (action: any) => {
  if (action.isCreate) {
    return 'CREATE';
  } else if (action.isUpdate) {
    return 'UPDATE';
  } else if (action.isStepFlow) {
    return 'TRANSITION';
  } else {
    return 'NONE';
  }
};

export const ActionsSection = ({ schema, schemaTypeId }: SchemaDetailsProps) => {
  const initializeForm = useAction(initializeSharedForm);
  const alertMessage = useAction(displayMessage);

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [actions, setActions] = useState<any[]>([]);
  const [pipeline, setPipeline] = useState<PipelineEntity>();
  const [selectedAction, setSelectedAction] = useState<SchemaActionEntity>();
  const [refetch, setRefetchActions] = useState(0);
  const refetchActions = () => setRefetchActions((refetch) => refetch + 1);

  const filteredActions = actions.filter((action) => {
    const schemaTypeFilter = schemaTypeId === 'all' ? true : action.schemaTypeId === schemaTypeId;
    const nameFilter = action.name.toLocaleLowerCase().includes(search);

    return schemaTypeFilter && nameFilter;
  });

  useEffect(() => {
    if (!schema?.id) return;

    let mounted = true;

    setIsLoading(true);
    Promise.all([
      getSchemaActions(schema.id),
      getSchemaPipelines(schema, schemaTypeId),
    ]).then(([actions, pipelines]) => {
      if (!mounted) return;

      setActions(actions);
      setPipeline(pipelines[0]);
      setIsLoading(false);
    }).catch(error => {
      if (!mounted) return;

      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not retrieve schema details. ' + message,
        type: 'error',
      });
      setIsLoading(false);
      setActions([]);
    });

    return () => {
      mounted = false;
    }
  }, [schema?.id, refetch, schemaTypeId]);

  const getSchemaActions = async (schemaId: string): Promise<SchemaActionEntity[]> => {
    // TODO: this endpoint should be /schemas/${schemaId}/actions
    return await httpGet(`SchemaModule/v1.0/schemas-actions/schema/${schemaId}`)
      .then((res) => {
        return res.data.data || [];
      });
  };

  const getSchemaPipelines = async (schema: SchemaEntity, schemaTypeId?: string | null): Promise<PipelineEntity[]> => {
    const schemaType = schema.types.find(type => type.id === schemaTypeId);
    return await httpGet(
      `SchemaModule/v1.0/pipelines/bymodule/${schema?.moduleName}/${schema?.entityName}${
        schemaType ? `?schemaType=${schemaType.name}` : '?schemaType='
      }`,
    ).then((res: any) => {
      return res.data?.data || [];
    });
};

  const showActionsForm = (action?: SchemaActionEntity) => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: action?.id ? 'Edit Action' : 'Create Action',
      formFields: getFormFields(schema, action, schemaTypeId === 'all' ? null : schemaTypeId, pipeline),
      entityName: 'SchemaAction',
    });  
  }

  const handleFormSubmit = async (params: FormReducerSubmitEvt) => {
    const { type, ...data } = params.data;
    if (type === 'CREATE') {
      data.isCreate = true;
      data.isUpdate = false;
      data.isStepFlow = false;
    }

    if (type === 'UPDATE') {
      data.isCreate = false;
      data.isUpdate = true;
      data.isStepFlow = false;
    }

    if (type === 'FLOW') {
      data.isCreate = false;
      data.isUpdate = false;
      data.isStepFlow = true;
    }

    if (schemaTypeId !== 'all' && !data.schemaTypeId) {
      data.schemaTypeId = schemaTypeId;
    }

    if (params.id) {
      // update action
      try {
      await httpPut(`SchemaModule/v1.0/schemas-actions/${params.id}`, data);
      alertMessage({
        body: 'Action updated',
        type: 'success',
      });
      refetchActions();
      } catch(error: any) {
        const message = getErrorMessage(error);
        alertMessage({
          body: 'Could not update action. ' + message,
          type: 'error',
        });
      }
    } else {
      // create action
      try {
        await httpPost(`SchemaModule/v1.0/schemas-actions`, { ...data, schemaTypeId });
        alertMessage({
          body: 'Action created',
          type: 'success',
        });
        refetchActions();
      } catch(error: any) {
        const message = getErrorMessage(error);
        alertMessage({
          body: 'Could not create action. ' + message,
          type: 'error',
        });
      }
    }
  };

  return (
    <>
      <Section
        className="actions-section"
        title="Actions"
        rightElement={
          <>
            <InputGroup
              type="text"
              leftIcon="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
            <button
              className="link-button"
              onClick={() => showActionsForm()}
            >
              <Icon icon="add" /> New
            </button>
          </>
        }
      >
        <SectionCard padded={false} className="limited-height">
          {isLoading && <div className="loading-page"><Spinner /></div>}
          {actions.length === 0 ? (
            <Empty icon="text-highlight" text="No actions defined yet" />
          ) : (
            <CardList bordered={false}>
            {filteredActions.map((action) => (
              <Card key={action.id} interactive>
                <span>{action.name}</span>
                <Tag fill={false}>{getActionType(action)}</Tag>
                <span className="actions">
                  <Icon
                    icon="edit"
                    className={Classes.TEXT_MUTED}
                    onClick={() => showActionsForm(action)}
                  />
                  <Icon
                    icon="new-text-box"
                    className={Classes.TEXT_MUTED}
                    onClick={() => setSelectedAction(action)}
                  />
                </span>
              </Card>
            ))}
            </CardList>
          )}
        </SectionCard>
      </Section>

      <FormModal
        formUUID={uuid}
        onSubmitEvent={handleFormSubmit}
      />

      <SchemaActionConfiguration
        schema={schema}
        schemaAction={selectedAction}
        onClose={() => setSelectedAction(undefined)}
        onUpdate={refetchActions}
      />
    </>
  )
}
