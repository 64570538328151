import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../..';
import MyCasesListItem from './MyCaseListItem';
import './styles.scss';

interface Props {
  userReducer: any;
}

const MyCasesList: React.FC<Props> = (props: Props) => {
  const { state } = useContext(MyCasesContext);
  const { userReducer } = props;

  // Replace this with cases data, filtered by the case status
  const priorityCases: DbRecordEntityTransform[] = Object.assign(state.cases, []);
  const otherCases: DbRecordEntityTransform[] = [];

  return (
    <Row className="myCasesListRow" align="top">
      <Col span={24} className="prioritySection">
        {/* Priority  */}
        <Row>
          <Col span={24} style={{ padding: '5px 10px', background: '#f3f5fd' }}>
            <h4 style={{ color: '#123ee0' }}>Priority</h4>
          </Col>
          <Col span={24} style={{}}>
            <Row justify="space-between">
              {priorityCases.map((item: DbRecordEntityTransform, index: number) => (
                <MyCasesListItem Case={item} index={index} />
              ))}
            </Row>
          </Col>
        </Row>

        {/* Other */}
        {/*<Row>*/}
        {/*  <Col span={24} style={{ padding: '5px 10px', background: '#F8F8F8' }}>*/}
        {/*    <h4 style={{ color: 'black' }}>Other</h4>*/}
        {/*  </Col>*/}
        {/*  <Col span={24}>*/}
        {/*    <Row justify="space-between">*/}
        {/*      {otherCases.map((item: DbRecordEntityTransform, index: number) => (*/}
        {/*        <MyCasesListItem Case={item} index={index} />*/}
        {/*      ))}*/}
        {/*    </Row>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});
export default connect(mapState, mapDispatch)(MyCasesList);
